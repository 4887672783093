<template>
  <div class="content-body pb-8">
    <div class="space-y-4">
      <div class="flex justify-between items-center">
        <div class="flex items-center gap-4">
          <ArrowForward direction="left" @handleClick="goBack()" class="cursor-pointer" />
          <h1 class="text-3xl font-bold">{{ pageName }}</h1>
        </div>
      </div>
      <div class="shadow-small card pt-4 pb-4 bg-white rounded-lg">
        <div class="space-y-4 px-6 w-1/2">
          <div class="border-b border-neutral-50 py-3 flex justify-between items-center">
            <p class="font-semibold flex gap-1 items-center">
              <span class=""><Label color="yellow" /></span>Leave Details
            </p>
          </div>
          <TextField label="Employee Name" type="text" borderEnabled v-model="leaveRequestDetails.employeeName" disabledWhite />
          <TextField label="Request No" type="text" borderEnabled v-model="leaveRequestDetails.leaveNo" disabledWhite />
          <TextField label="Department" type="text" borderEnabled v-model="leaveRequestDetails.department" disabledWhite />
          <TextField label="Leave Type" type="text" borderEnabled v-model="leaveRequestDetails.leaveType" disabledWhite />
          <TextField label="Start Time" :type="`${leaveRequestDetails.leaveTypeUnit === 'DAYS' ? 'date' : 'datetime-local'}`" borderEnabled v-model="leaveRequestDetails.startDate" disabledWhite />
          <TextField label="End Time" :type="`${leaveRequestDetails.leaveTypeUnit === 'DAYS' ? 'date' : 'datetime-local'}`" borderEnabled v-model="leaveRequestDetails.endDate" disabledWhite />
          <TextField
            :label="`Leave Duration (${leaveRequestDetails.leaveTypeUnit.toLowerCase()})`"
            type="text"
            borderEnabled
            v-model="leaveRequestDetails.leaveDuration"
            disabledWhite
            notes="Automatic calculation of duration based on schedules"
          />
          <TextArea label="Reason for leave" borderEnabled v-model="leaveRequestDetails.reason" disabledWhite />
          <div v-if="leaveRequestDetails?.approvalUrl">
            <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-2">Approval Document</label>
            <div class="py-10 border-dashed border-2  border-neutral-500 rounded-lg relative">
              <div class="text-sm text-yellow absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <a class="flex items-center gap-2 cursor-pointer" :href="leaveRequestDetails?.approvalUrl + `?token=${accessToken}`" target="_blank">
                  <Attach color="#3b82f6" />
                  <p>View File</p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center gap-2 border-t border-neutral-50 pt-4 mt-5">
          <Button
            :disabled="this.leaveRequestDetails.status !== 'On Review'"
            type="greenTertiary"
            size="icon"
            :forIcon="{ color: this.leaveRequestDetails.status === 'On Review' ? 'green' : '', width: '14', height: '14' }"
            :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/CheckmarkCircle')"
            buttonText="Approve"
            @action="confirmAction('APPROVED')"
            additionalClass="ml-6 py-2 px-3"
          />
          <Button
            :disabled="this.leaveRequestDetails.status !== 'On Review' && this.leaveRequestDetails.status !== 'Approved'"
            type="deleteTertiary"
            size="icon"
            :forIcon="{ color: this.leaveRequestDetails.status === 'On Review' || this.leaveRequestDetails.status === 'Approved' ? 'red' : '', width: '14', height: '14' }"
            :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/CrossCircle')"
            buttonText="Reject"
            @action="confirmAction('REJECTED')"
            additionalClass="py-2 px-3"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { upperCaseFirstWord, showVueToast } from '@/utils'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
export default {
  components: {
    Chevron: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/Chevron'),
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    TextArea: () => import(/* webpackChunkName: "TextArea" */ '@/components/Form/TextArea'),
    Label: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Label'),
    Attach: () => import(/* webpackChunkName: "Attach" */ '@/components/Icons/Attach'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown'),
    ArrowForward: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/ArrowForward')
  },
  data() {
    return {
      leaveRequestDetails: {
        leaveTypeUnit: ''
      }
    }
  },
  computed: {
    ...mapGetters('common', ['isUnauthorized']),
    ...mapGetters('customer', ['customer']),
    customerId() {
      return localStorage.getItem('client')
    },
    leaveTypeNo() {
      return this.$route.params.id
    },
    pageName() {
      return this.$route.meta.label
    },
    accessToken() {
      return this.customer?.token
    }
  },
  watch: {},
  created() {
    this.fetchingData()
  },
  methods: {
    ...mapActions('humanresource', ['GET_LEAVE_REQUEST_LIST', 'RESPONSE_LEAVE_REQUEST']),
    ...mapActions('common', ['showLoading', 'hideLoading']),
    //     {
    //     "id": 106,
    //     "leaveNo": "2023080034",
    //     "employeeName": "Fitri 103",
    //     "createdDate": "2023-08-01T02:10:45.949+00:00",
    //     "status": "On Review",
    //     "department": "Edu & Curriculum",
    //     "leaveType": "WFA",
    //     "leaveTypeId": 29,
    //     "leaveTypeUnit": "DAYS",
    //     "leaveTypeDurationType": "LIMITED",
    //     "startDate": "2023-08-28T00:00:00.000+00:00",
    //     "endDate": "2023-08-31T00:00:00.000+00:00",
    //     "leaveDuration": 4,
    //     "reason": "Ada acara keluarga di Malang",
    //     "approvalUrl": "https://dev-media.g2academy.co/1/hr/53/Screenshot_20230716-202738_Instagram.jpg"
    // },

    goBack() {
      this.$router.go(-1)
    },
    confirmAction(status) {
      this.showLoading()
      const payload = {
        status,
        note: 'HR notes'
      }
      this.RESPONSE_LEAVE_REQUEST({ payload, leaveRequestId: this.leaveRequestDetails.id })
        .then((res) => {
          if (res?.data?.code === 200) {
            showVueToast('Success', 'success', 2000)
            this.goBack()
          } else {
            showVueToast('Your request is failed!', 'error', 2000)
            this.hideLoading()
          }
        })
        .catch(() => {
          showVueToast('Your request is failed!', 'error', 2000)
          this.hideLoading()
        })
    },
    utcToLocal(day, format) {
      const localTime = dayjs.utc(day).local().format(format)
      return localTime
    },
    convertDate(date, type) {
      if (type === 'DAYS') return dayjs(date).format('YYYY-MM-DD')
      const format = 'YYYY-MM-DD HH:mm'
      return this.utcToLocal(date, format)
    },
    fetchingData() {
      this.showLoading()
      let params = {
        q: this.leaveTypeNo,
        customerId: this.customerId,
        direction: 'DESC',
        limit: 10,
        page: 0
      }
      this.GET_LEAVE_REQUEST_LIST({ params }).then((res) => {
        if (res?.code === 200) {
          const leaveRequest = res?.data[0]
          leaveRequest.department = upperCaseFirstWord(leaveRequest.department)
          leaveRequest.startDate = this.convertDate(leaveRequest.startDate, leaveRequest.leaveTypeUnit)
          leaveRequest.endDate = this.convertDate(leaveRequest.endDate, leaveRequest.leaveTypeUnit)
          this.leaveRequestDetails = leaveRequest
        }
        this.hideLoading()
      })
    }
  }
}
</script>

<style scoped>
</style>